<template>
  <div class="app-container">
          <el-dialog :visible.sync="editSkuVisible" title="Add/Reduce SkuStock" width="20%">
        <el-form align="center">
          <el-form-item label="Sku" align="center">
            <el-select v-model="editSkuParam.id" v-if="editSkuProduct">
              <el-option
              v-for="item in editSkuProduct.skuStocks"
                :key="item.id"
                :value="item.id"
                :label="item.sku">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Add/Reduce Value">
            <el-input-number v-model="editSkuParam.value"></el-input-number>
          </el-form-item>
          <el-form-item>
              <el-button @click="cancelEditSku">Cancel</el-button>
              <el-button type="primary" @click="submitEditSku">Submit</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    <el-card class="filter-container" shadow="never">
      <div>
        <i class="el-icon-search"></i>
        <span>筛选搜索</span>
        <el-button
          style="float: right"
          @click="handleSearchList()"
          type="primary"
          size="small"
        >
          查询结果
        </el-button>
        <el-button
          style="float: right; margin-right: 15px"
          @click="handleResetSearch()"
          size="small"
        >
          重置
        </el-button>
      </div>
      <div style="margin-top: 15px">
        <el-form
          :inline="true"
          :model="listQuery"
          size="small"
          label-width="140px"
        >
          <el-form-item label="Name：">
            <el-input
              v-model="listQuery.keyword"
              placeholder="Product Name"
            ></el-input>
          </el-form-item>
          <el-form-item label="Merchant: ">
            <el-select v-model="listQuery.merchantId" placeholder="Select Merchant" collapse-tags>
              <div class="el-input">
                <input type="text" class="el-input__inner" v-model="merchantSearchValue" @keyup="
                  handleFilter(
                    merchantOptions,
                    'merchantOptionsFiltered',
                    merchantSearchValue,
                  )
                " />
              </div>
              <el-option v-for="item in merchantOptionsFiltered" :key="item.id" :value="item.id" :label="item.name">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
    <el-card class="operate-container" shadow="never">
      <i class="el-icon-tickets"></i>
      <span>数据列表</span>
      <el-button class="btn-add" @click="handleAddProduct()" size="mini">
        添加
      </el-button>
    </el-card>
    <div class="table-container">
      <el-table
        ref="productTable"
        :data="list"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        v-loading="listLoading"
        border
      >
        <el-table-column
          type="selection"
          width="60"
          align="center"
        ></el-table-column>
        <el-table-column label="商品名称" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.name }}</p>
          </template>
        </el-table-column>
        <el-table-column label="Product Merchant" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.merchant }}</p>
          </template>
        </el-table-column>
        <el-table-column label="Merchant Menu" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.merchantMenu }}</p>
          </template>
        </el-table-column>
        <el-table-column label="1st Category" align="center">
          <template slot-scope="scope">{{ scope.row.categories.find(c => c.depth == 0).name }}</template>
        </el-table-column>
        <el-table-column label="Status" align="center">
          <template slot-scope="scope">{{ scope.row.status }}</template>
        </el-table-column>
        <el-table-column label="Org. Price" align="center">
          <template slot-scope="scope">{{
            scope.row.originalPrice.display
          }}</template>
        </el-table-column>
        <el-table-column label="Lowest Price" align="center">
          <template slot-scope="scope">{{
            scope.row.lowestPrice.display
          }}</template>
        </el-table-column>
        <el-table-column label="Support Bulk?" align="center">
          <template slot-scope="scope"
            >{{ scope.row.bulkStatus ? "Yes" : "No" }}
            <p>
              <el-button
                type="success"
                size="mini"
                v-if="scope.row.bulkStatus == false"
                @click="handleSupportBulk(scope.row.id)"
                >Enable Bulk</el-button
              >
              <el-button
                type="danger"
                size="mini"
                v-if="scope.row.bulkStatus == true"
                @click="handleDisableBulk(scope.row.id)"
                >Disable Bulk</el-button
              >
            </p>
          </template>
        </el-table-column>
        <el-table-column label="Bulk Threshold" align="center">
          <template slot-scope="scope">{{ scope.row.bulkThreshold }}</template>
        </el-table-column>
        <el-table-column label="Ongoing Bulks" align="center">
          <template slot-scope="scope">
            {{ scope.row.ongoingBulks }}
            <p>
              <el-button
                type="success"
                size="mini"
                v-if="scope.row.bulkStatus"
                @click="handleOngoingBulk(scope.row)"
                >Open</el-button
              >
            </p>
          </template>
        </el-table-column>
        <el-table-column label="Pictures Num" align="center">
          <template slot-scope="scope">{{
            scope.row.imageURLs.length
          }}</template>
        </el-table-column>
        <el-table-column label="SKU Stocks" align="center" width="300px">
          <template slot-scope="scope">
            <div
              v-for="(item, index) in scope.row.skuStocks"
              v-bind:key="index"
            >
              {{ item.sku }}, Stock: {{ item.stock }}, Available: {{ item.stock - item.lockedStock + item.pendingStock }}
            </div>
            <el-button
              icon="el-icon-edit"
              type="primary"
              size="mini"
              @click="handleEditSkuStock(scope.row)"
            ></el-button>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="160" align="center">
          <template slot-scope="scope">
            <p>
              <el-button
                type="success"
                size="mini"
                v-if="scope.row.status == 'CREATED'"
                @click="handlePublishProduct(scope.row.id)"
                >Publish</el-button
              >
            </p>
            <p>
              <el-button
                type="danger"
                size="mini"
                v-if="scope.row.status == 'PUBLISHED'"
                @click="handleSuspendProduct(scope.row.id)"
                >Suspend</el-button
              >
            </p>
            <p>
              <el-button
                type="warning"
                size="mini"
                v-if="scope.row.status == 'SUSPENDED'"
                @click="handlePublishProduct(scope.row.id)"
                >Restore</el-button
              >
            </p>
            <p>
              <el-button size="mini" @click="handleUpdateProduct(scope.row)"
                >Update</el-button
              >
            </p>
            <p>
              <el-button
                type="danger"
                size="mini"
                @click="handleDelete(scope.row)"
                >Delete</el-button
              >
            </p>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="batch-operate-container">
      <el-select size="small" v-model="operateType" placeholder="批量操作">
        <el-option
          v-for="item in operates"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-button
        style="margin-left: 20px"
        class="search-button"
        @click="handleBatchOperate()"
        type="primary"
        size="small"
      >
        确定
      </el-button>
    </div>
    <div class="pagination-container">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes,prev, pager, next,jumper"
        :page-size="listQuery.count"
        :page-sizes="[5, 10, 20]"
        :current-page.sync="listQuery.page"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {
  fetchList,
  deleteProduct,
  updateProductStatus,
  supportBulk,
  disableBulk,
  setSkuStock,
} from '@/api/product';
import { fetchList as fetchSkuStockList } from '@/api/skuStock';
import { fetchList as fetchProductAttrList } from '@/api/productAttr';
import { fetchList as fetchProductCateList } from '@/api/productCate';
import { fetchList as fetchMerchantList } from '@/api/merchant';

const defaultListQuery = {
  page: 1,
  count: 5,
  keyword: null,
  merchantId: null,
};
const defaultSkuParam = {
  id: null,
  value: null,
};
export default {
  name: 'productList',
  data() {
    return {
      operates: [
        {
          label: '商品上架',
          value: 'publishOn',
        },
      ],
      operateType: null,
      listQuery: { ...defaultListQuery },
      list: null,
      total: null,
      listLoading: true,
      selectProductCateValue: null,
      multipleSelection: [],
      productCateOptions: [],

      merchantOptions: [],
      merchantOptionsFiltered: null,
      merchantSearchValue: null,

      editSkuProduct: null,
      editSkuVisible: false,
      editSkuParam: { ...defaultSkuParam },
    };
  },
  created() {
    this.getList();
    this.getProductCateList();
    this.getMerchantList();
  },
  watch: {
    selectProductCateValue(newValue) {
      if (newValue != null && newValue.length == 2) {
        this.listQuery.productCategoryId = newValue[1];
      } else {
        this.listQuery.productCategoryId = null;
      }
    },
  },
  methods: {
    handlePublishProduct(id) {
      this.$confirm('Publish this product?', 'Warning', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning',
      }).then(() => {
        updateProductStatus({ productId: id, productStatus: 'PUBLISHED' }).then(
          (response) => {
            this.$message({
              message: '修改成功',
              type: 'success',
              duration: 1000,
            });
            this.getList();
          },
        );
      });
    },
    handleSuspendProduct(id) {
      this.$confirm('Suspend this product?', 'Warning', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning',
      }).then(() => {
        updateProductStatus({ productId: id, productStatus: 'SUSPENDED' }).then(
          (response) => {
            this.$message({
              message: '修改成功',
              type: 'success',
              duration: 1000,
            });
            this.getList();
          },
        );
      });
    },
    getProductSkuSp(row, index) {
      const spData = JSON.parse(row.spData);
      if (spData != null && index < spData.length) {
        return spData[index].value;
      }
      return null;
    },
    getList() {
      this.listLoading = true;
      const param = JSON.parse(JSON.stringify(this.listQuery));
      param.page -= 1;
      fetchList(param).then((response) => {
        this.listLoading = false;
        this.list = response.data.data;
        this.total = response.data.recordsTotal;
      });
    },
    getProductCateList() {
      fetchProductCateList().then((response) => {
        const list = response.data.data;
        this.productCateOptions = [];
        for (let i = 0; i < list.length; i += 1) {
          const children = [];
          if (list[i].children != null && list[i].children.length > 0) {
            for (let j = 0; j < list[i].children.length; j += 1) {
              children.push({
                label: list[i].children[j].name,
                value: list[i].children[j].id,
              });
            }
          }
          this.productCateOptions.push({
            label: list[i].name,
            value: list[i].id,
            children,
          });
        }
      });
    },
    getMerchantList() {
      fetchMerchantList().then((response) => {
        this.merchantOptions = response.data.data;
        this.merchantOptions.sort((a, b) => a.name.localeCompare(b.name));
        this.merchantOptions.unshift({
          id: null,
          name: '--No Selected Merchant--',
        });
        this.merchantOptionsFiltered = this.merchantOptions;
      });
    },
    handleFilter(src, dst, keyword) {
      if (keyword == null || keyword == '') return;
      const matched = src.filter((x) => x.name.toLowerCase().includes(keyword.toLowerCase()));
      const unmatched = src.filter(
        (x) => !x.name.toLowerCase().includes(keyword.toLowerCase()),
      );
      this[dst] = matched.concat(unmatched);
    },
    handleSearchList() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleAddProduct() {
      this.$router.push({ path: '/productService/addProduct' });
    },
    handleSizeChange(val) {
      this.listQuery.page = 1;
      this.listQuery.count = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleResetSearch() {
      this.selectProductCateValue = [];
      this.listQuery = { ...defaultListQuery };
    },
    handleDelete(row) {
      this.$confirm('是否要进行删除操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        deleteProduct(row.id).then((response) => {
          this.$message({
            message: 'Successfully deleted',
            type: 'success',
            duration: 1000,
          });
          this.getList();
        });
      });
    },
    handleUpdateProduct(row) {
      this.$router.push({
        path: '/productService/updateProduct',
        query: { id: row.id },
      });
    },
    handleSupportBulk(id) {
      this.$confirm('Support bulk for this product?', 'Warning', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning',
      }).then(() => {
        supportBulk(id).then((response) => {
          this.$message({
            message: '修改成功',
            type: 'success',
            duration: 1000,
          });
          this.getList();
        });
      });
    },
    handleDisableBulk(id) {
      this.$confirm('Disable bulk for this product?', 'Warning', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning',
      }).then(() => {
        disableBulk(id).then((response) => {
          this.$message({
            message: '修改成功',
            type: 'success',
            duration: 1000,
          });
          this.getList();
        });
      });
    },
    handleCreateBulk(product) {
      this.$router.push({
        path: '/bulkService/createBulk',
        query: {
          productId: product.id,
          productName: product.name,
          threshold: product.bulkThreshold,
        },
      });
    },
    handleOngoingBulk(product) {
      this.$router.push({
        path: '/bulkService/ongoingBulk',
        query: { id: product.id },
      });
    },
    handleEditSkuStock(product) {
      this.editSkuProduct = product;
      this.editSkuVisible = true;
    },
    submitEditSku() {
      this.$confirm('Edit Sku?', 'Warning', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning',
      }).then(() => {
        setSkuStock(this.editSkuParam).then(() => {
          this.$message({
            message: 'Success',
            type: 'success',
            duration: 1000,
          });
          this.cancelEditSku();
          this.getList();
        });
      });
    },
    cancelEditSku() {
      this.editSkuVisible = false;
      this.editSkuParam = { ...defaultSkuParam };
    },
  },
};
</script>
<style></style>
